









import { Component, Prop, Vue } from 'vue-property-decorator'
import { RouteRecord } from 'vue-router'
import Stepper from '@/components/Stepper.vue'
import constants from '@/constants'

@Component({
  name: 'Application',
  components: {
    Stepper
  }
})
export default class Application extends Vue {
  @Prop() private steps!: number;
  transitionName = constants.transitionNames.SLIDE_LEFT

  get step () {
    return this.$route.matched[1].meta.step
  }

  created () {
    this.$router.beforeEach((to, from, next) => {
      const matchedPath = from.matched.find((m: RouteRecord) => m.path === from.path)
      const isParentExist = matchedPath && matchedPath.parent
      this.transitionName = isParentExist ? constants.transitionNames.SLIDE_LEFT : constants.transitionNames.SLIDE_RIGHT
      next()
    })
  }
}
